import {useState} from 'react';

import {
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  IconButton,
  Grid,
} 
from '@mui/material';

import {
  IoIosPlay,
  IoIosPause,
  IoMdFingerPrint,
} from "react-icons/io";

const itemData = [
  {
    img: 'https://m.media-amazon.com/images/I/71wDnUTsaVL._UF1000,1000_QL80_.jpg',
    title: 'Primrose Flower Voice',
    author: '@花たん',
  },
  {
    img: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/92/cd/0e/92cd0e6b-2c24-ffd0-4cab-d28d87130434/SCGA-00041.jpg/1200x1200bf-60.jpg',
    title: 'Flower Rail',
    author: '@花たん',
  }
  ];

function App(){
  return(<>
    <ImageList style={{width:"100%"}} >
      {/* <ImageListItem key="Subheader" >
        <ListSubheader component="div">ListSubHeader</ListSubheader>
      </ImageListItem> */}
      {
        itemData.map((item)=>{
          return <ImageListItem key={item.img} >
            <img 
              alt={item.title} 
              src={item.img}
            />
            <ImageListItemBar 
              title={item.title}
              subtitle={item.author}
              position='bottom'
              actionIcon={
                <IconButton style={{color:"#fff"}}>
                  <IoMdFingerPrint/>
                </IconButton>
              }
            />
          </ImageListItem>
        })
      }
    </ImageList>
  </>)
}
export default App;