import {useEffect, useState} from 'react';

import './extstyle.css';

import {
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  IconButton,
  Grid,
  TextField,
  Button,
  ButtonGroup,
  Paper,
  Tooltip,
  Alert,
} 
from '@mui/material';

import {
  IoIosPlay,
  IoIosPause,
  IoMdFingerPrint,
} from "react-icons/io";

import { FaCentos, FaSearch  } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { GiGooeyEyedSun } from "react-icons/gi";

import LoadingUtil from './bak/LoadingPlacehold_demo';
import ImgList from './example/demo_imgList';
import CardUtil from './example/demo_card';
import BackgroundEffect from './effect/BackgroundEffect';

function App(){
  const cusBoxShadow = "0 0 30px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.3)";

  return(
    <Grid container padding={2} style={{backgroundColor: "rgba(255,255,255,0)"}}>

      {/* header */}

      <Grid container item sx={{justifyContent:"center", alignItems:"center", backgroundColor:"white",padding:"16px",borderRadius:1,boxShadow:cusBoxShadow}}>
        <Grid container item sm={3} justifyContent={{xs:'center', sm:'flex-start'}} alignItems="center" style={{padding:"16px"}}>
          <GiGooeyEyedSun size={32}/>
          &nbsp;
          <h2>OOKAMI</h2>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;<small style={{color:"#aaa"}}>vrchat:やつれた狼 discord:lousai.</small></div>
        </Grid>
        <Grid container item sm={5} sx={{justifyContent:"center", alignItems:"center"}}></Grid>
        <Grid container item sm={4} sx={{justifyContent:"flex-end", alignItems:"center"}}>
          <ButtonGroup size="small" sx={{width:"100%"}}>
            <TextField id="search" size="small" label="Search" fullWidth />
            <Tooltip title="You don't have permission to do this" followCursor>
              <IconButton variant="outlined">
                <CiSearch />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container item xs={12} pt={2} >
        <ImgList/>
      </Grid>

      <Grid container item spacing={2} pt={2}>
        <Grid container item sm={4} md={4} lg={4}>
          <Paper elevation={0} style={{width:"100%",boxShadow:cusBoxShadow}}>
            <CardUtil 
              title = "The Flower of Dim World"
              subtitle = "花たん"
              img = "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/68/31/7f/68317f23-70c7-f19f-10c3-6ef85207ce87/SCGA-00012.jpg/1200x1200bb.jpg" />
              
          </Paper>
        </Grid>

        <Grid container item sm={4} md={4} lg={4}>
          <Paper elevation={0} style={{width:"100%",boxShadow:cusBoxShadow}}>
            <CardUtil
              title = "FLOWER"
              subtitle = "花たん"
              img="https://i1.sndcdn.com/artworks-000054269289-jzrib3-t500x500.jpg"/>
          </Paper>
        </Grid>

        <Grid container item sm={4} md={4} lg={4}>
          <Paper elevation={0} style={{width:"100%",boxShadow:cusBoxShadow}}>
            <CardUtil
              title = "Primrose Flower Voice"
              subtitle = "花たん"
              img="	https://i.pinimg.com/736x/40/ae/93/40ae93dce45a388bf188837fbedcd892.jpg" />
          </Paper>
        </Grid>

      </Grid>

      <Grid container item sm={12} pt={2}>
        <Paper elevation={0} style={{width:"100%",padding:"16px",boxShadow:cusBoxShadow}}>
          <h3>Description</h3>
          <hr style={{margin:"8px 0px 8px 0px"}}/>
          <h5>花になりたいのです　名前はなくていいのです　一人になりすぎたときは　わたしをおもいだして</h5>
        </Paper>
      </Grid>

      <Grid container item pt={2}>
        <Paper elevation={0} style={{width:"100%",padding:"16px",boxShadow:cusBoxShadow}}>  
          <h3>Newest </h3>
          <hr style={{margin:"8px 0px 8px 0px"}}/>
          <LoadingUtil loading = {true} count={12}/>
          <h3>All</h3>
          <hr style={{margin:"8px 0px 8px 0px"}}/>
          <LoadingUtil loading = {true} count={60}/>
        </Paper>
      </Grid>

      {/* footer */}
      <Grid container item style={{margin:"16px"}}>
        <Grid container item direction="column" style={{justifyContent:"center", alignItems:"center"}}>
          <h6>Powered by React | Material UI | Nginx</h6>
          <small>2024 - Now</small>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default App;