import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardActionArea,
    Typography,
    Button,
} from '@mui/material';

function App({img,title,subtitle}) {
    return (
      <Card sx={{width:"100%"}} elevation={0} style={{boxShadow:"0 0 10px rgba(200, 200, 200, 0.5), 0 0 10px rgba(200, 200, 200, 0.2), 0 0 20px rgba(200, 200, 200, 0.1)"}}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="400"
            src={img}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle} 
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={{justifyContent:"flex-end",alignItems:"center"}}>
          <Button size="small" color="primary" variant="outlined">Share</Button>
          <Button size="small" color="primary" variant='contained'>Visit</Button>
        </CardActions>
      </Card>
    );
  }

  export default App;